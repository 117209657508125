<template>
  <ValidationObserver ref="observer">
    <b-row align-v="center" align-h="center">
      <b-col cols="8">
        <b-card v-if="azienda" :title="azienda.ragioneSociale">
          <b-container fluid>
            <b-row class="my-1">
              <b-col sm="12">
                 <b-form-group
                  class="col-12"
                  :label="'Logo'"
                  :label-for="`input-logo`">
                  <b-form-file
                    :id="`input-logo`"
                    class="form-control"
                    v-model="upload_file"
                    :state="upload_file ? Boolean(upload_file) : null"
                    placeholder="Scegli nuovo logo da allegare..."
                    drop-placeholder="Trascina qui il file..."
                    browse-text="Allega"
                    :max-size="2 * 1024 * 1024"
                  />
                <div style="margin-top:5px" v-if="upload_file" class="text-success">Nuovo Logo: {{ upload_file ? upload_file.name : '' }}</div>
                <b-img :src="getLogoUrl()" alt="Nessun logo caricato" style="height:50px;margin-top:5px" />
                <!--<button @click="test_logo()"> test </button>-->
                </b-form-group>
              </b-col>
              </b-row>
            <b-row class="my-1" v-for="(val,key) in azKeysMap" :key="key">
              <!-- <b-col sm="3">
                <label :for="`input-${key}`">{{ val.text }}:</label>
              </b-col> -->
              <b-col sm="12">
                <b-form-group
                  class="col-12"
                  :label="val.text"
                  :label-for="`input-${key}`"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="val.text"
                    :vid="key"
                    :rules="val.rules"
                  >
                    <b-form-input
                      :id="`input-${key}`"
                      v-model="azienda[key]"
                      :type="val.type"
                      :state="azienda[key] ? azienda[key].length > val.state : null"
                    />
                    <!-- <b-form-invalid-feedback :id="`input-${key}-feedback`">
                      {{ val.feedback}}
                    </b-form-invalid-feedback> -->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
          <b-row align-h="end">
            <b-button
              class="m-1"
              :disabled="hasErrors || isSubmitting"
              :variant="`${isDark ? 'warning' : 'primary'}`"
              @click="submitAzienda"
            >
              Aggiorna dati
            </b-button>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
extend('required', {
  ...required,
  message: "Campo da compilare"
});
extend('email', {
  ...email,
  message: "Inserire una email valida"
});
import {
  BImg, BCard, BCardSubTitle, BTable, BBadge, BRow, BCol, BFormGroup, BFormFile,
  BFormInput, BButton, BInputGroup, BContainer, BFormInvalidFeedback
} from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
// import { computed } from '@vue/composition-api'
import { computed } from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      azienda: null,
      upload_file: null,
      dateOptions: {  timeStyle:"short", dateStyle: 'medium' },
      isSubmitting: false,
      azKeysMap: {
        ragioneSociale: {
          text: 'Ragione sociale',
          type: 'text',
          state: 2,
          feedback: 'Inserisci almeno 3 caratteri',
          rules: 'required'
        },
        partitaIva: {
          text: 'Partita iva',
          type: 'text',
          state: 2,
          feedback: 'Inserisci partita iva',
          rules: 'required'
        },
        codiceFiscale: {
          text: 'Codice fiscale',
          type: 'text',
          state: 2,
          feedback: 'Inserisci codice fiscale'
        },
        tipoAzienda: {
          text: 'Descrizione azienda',
          type: 'text',
          state: 14,
          feedback: 'Inserisci almeno 15 caratteri'
        },
        codiceAteco: {
          text: 'Codice ateco',
          type: 'text',
          state: 2,
          feedback: 'Inserisci codice ateco'
        },
        indL: {
          text: 'Indirizzo sede legale',
          type: 'text',
          state: 2,
          feedback: 'Inserisci almeno 3 caratteri',
          rules: 'required'
        },
        indEmailL: {
          text: 'Email sede legale',
          type: 'email',
          state: 2,
          feedback: 'Inserisci un\'email valida',
          rules: 'required|email'
        },
        indPecL: {
          text: 'Pec sede legale',
          type: 'email',
          state: 3,
          feedback: 'Inserisci un\'email valida'
        },
        indTelL: {
          text: 'Telefono sede legale',
          type: 'tel',
          state: 3,
          feedback: 'Inserisci un numero di telefono valido'
        },
        indC: {
          text: 'Indirizzo sede commerciale',
          type: 'text',
          state: 3,
          feedback: 'Inserisci un\'indirizzo'
        },
        // indEmailC: 'Email sede commerciale', // Email principale, non modificabile
        indPecC: {
          text: 'Pec sede commerciale',
          type: 'text',
          state: 3,
          feedback: 'Inserisci un\'email valida'
        },
        indTelC: {
          text: 'Telefono sede commerciale',
          type: 'tel',
          state: 3,
          feedback: 'Inserisci un numero di telefono valido'
        },
      },
    }
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')
    return { isDark }
  },
  components: {
    BImg,
    BCard,
    BCardSubTitle,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormFile,
    BButton,
    BInputGroup,
    BContainer,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  mounted() {
    this.getAzienda()
  },
  computed: {
    hasErrors() {
      const errors = this.$refs.observer.errors;
      return Object.keys(errors).some((key) => errors[key].length > 0);
    },
  },
  methods: {
    getAzienda() {
      this.$http.get('/az/getDetails').then(res => {
        // console.log('AZIENDA ',res)
        delete res.data.subscription
        delete res.data.id
        delete res.data.createdAt
        delete res.data.updatedAt
        delete res.data.indEmailC // Never update
        delete res.data.indNumeroL
        delete res.data.indTipoViaL
        delete res.data.indNumeroC
        delete res.data.indTipoViaC
        this.azienda = res.data
      })
    },
    getLogoUrl()
    {
      let logoAzienda = null
        if (this.azienda.logoFile.startsWith("public/")) {
          logoAzienda = this.azienda.logoFile.slice(7);
        } else {
          logoAzienda = this.azienda.logoFile;
        }
        return process.env.VUE_APP_BACKEND_API+'/'+logoAzienda

    },

    buildSaveObject() {
      delete this.azienda.logoFile
      delete this.azienda.logoMimeType
      const entries = Object.entries(this.azienda)
      const nonEmptyOrNull = entries.filter(([key,val]) => val !== '' && val !== null)
      const output = Object.fromEntries(nonEmptyOrNull)
      return output
    },
    submitAzienda() {
      this.isSubmitting = true;
      if (this.upload_file) {
        const formData = new FormData();
        formData.append('file', this.upload_file, this.upload_file.name);
        this.$http.post('/az/uploadLogo', formData).then(res => {
          if (!res || res.status != 200) {
            this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Errore',
              icon: 'AlertTriangleIcon',
              variant: 'error',
            },
          })
          }
          else
          {
            console.log("Correct")
          }
          
          }
        )
      }
      this.$http.post('/az/update', this.buildSaveObject()).then(res => {
        // console.log('Segnalazione UPDATE ',res)
        if (res && res.status == 200) {
          this.titolo = ''
          this.body = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Azienda',
              text: 'dati aggiornati con successo',
              icon: 'FileTextIcon',
              variant: 'success',
            },
          })
          // update contenutos
          this.getAzienda()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Errore',
              icon: 'AlertTriangleIcon',
              variant: 'error',
            },
          })
        }
        
      })
      .catch(error => {
        // console.warn(error.response.data);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Errore',
            text: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'error',
          },
        })
      })
      this.isSubmitting = false;
    },
    /*
    test_logo()
    {
      if (this.upload_file) {
        const formData = new FormData();
        formData.append('file', this.upload_file, this.upload_file.name);
        this.$http.post('/az/uploadLogo', formData).then(res => {
          console.log("Correct")
          this.getAzienda()}
          )
      }
    },*/
  }
}
</script>

<style>

</style>
